import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useScene } from "../contexts/sceneContext";

const Header = styled.div`
  text-align: center;
  padding: 16px;
  position: absolute;
  z-index: 10;
  width: 100%;
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  & h3 {
    margin: 0;
    margin-left: 24px;
  }
  & span {
    display: inline-block;
  }
`;

const NavigateButton = styled.button`
  background: transparent;
  border: none;
  font-family: grotesk;
  transition: 0.1s ease-in;
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: 16px;
  padding: 16px;
  &:hover {
    opacity: 0.5;
  }
`;

const DarkModeButton = styled.button`
  position: absolute;
  right: 24px;
  z-index: 100;
  bottom: 24px;
  padding: 0;
  font-family: grotesk;
  background: transparent;
  margin: 0;
  cursor: pointer;
  transition: 0.2s ease-in;
  border-radius: 50%;
  border: 1px solid
    ${({ darkMode }) => (darkMode ? "rgba(255, 255, 255, 0.5)" : "#222")};
  height: 35px;
  width: 35px;
  color: ${({ darkMode }) => (darkMode ? "#fff" : "#111")};
  font-size: 16px;
`;
const Footer = styled.div`
  position: absolute;
  z-index: 10;
  color: ${({ darkMode }) => (darkMode ? "#fff" : "#222")};
  bottom: 24px;
  left: 24px;
  h1 {
    marign: 0;
    font-size: 16px;
  }
  h2 {
    marign: 0;
    font-size: 14px;
  }
  a {
    font-size: 14px;
    color: ${({ darkMode }) => (darkMode ? "#fff" : "#222")};
  }
`;
const Copyright = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;
const ChapterHeader = ({ color }) => {
  const { sceneConfig, setConfig } = useScene();

  const setDarkMode = useCallback(() => {
    setConfig((prev) => ({ ...prev, darkMode: !prev.darkMode }));
  }, [setConfig]);

  return (
    <>
      <DarkModeButton onClick={setDarkMode} darkMode={sceneConfig.darkMode}>
        {sceneConfig.darkMode ? "日" : "月"}
      </DarkModeButton>
      <Footer darkMode={sceneConfig.darkMode}>
        <h2>est. 2016</h2>
        <h2>
          co-founded by two storytellers <br />
          making immersive experiences
        </h2>

        <a href={`mailto:info@ctrlncode.com`}>Talk to us</a>
        <Copyright>© 2024 CTRL N CODE Limited. All rights reserved.</Copyright>
      </Footer>
      <Header color={color}>
        {/* <h3 ref={headerRef}>Chapter {chapter.name}</h3>
        <NavigateButton onClick={onClick} color={color}>
          →{" "}
        </NavigateButton> */}
      </Header>
    </>
  );
};

export default ChapterHeader;
